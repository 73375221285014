import {getCurrentInstance, reactive, toRefs, onBeforeMount, onMounted, defineComponent, nextTick, computed} from 'vue';
import PdfEngineUtil ,{IPdfEngineDataObj} from "./pdfEngineUtil";
import VuePdfEmbed from "vue-pdf-embed";
// @ts-ignore
import { createLoadingTask } from "vue3-pdfjs/esm";
export default defineComponent ({
    name: "pdfEngine",
    props:{
        pdfEngineParams: {
            type: Object,
            default:()=>{return{}}
        },
    },
    title: "pdf预览",
    components: {VuePdfEmbed},
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let pdfEngineParams=props.pdfEngineParams;
        let dataObj:IPdfEngineDataObj=reactive<IPdfEngineDataObj>({
            utilInst:{} as any,
            pdfEngineParams:{},
            scale:1
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new PdfEngineUtil(proxy,dataObj);
            dataObj.utilInst.initPdfEngineParams(pdfEngineParams);//初始化列表引擎，计算设置相关参数
        })
        onMounted(()=>{
            const loadingTask = createLoadingTask(dataObj.pdfEngineParams.source);
            loadingTask.promise.then((pdf:any) => {
                dataObj.pdfEngineParams.numPages = pdf.numPages;
            });
        })
        //缩放
        const scaleFun=computed(()=>{
            return (index:any) => {
                let scale = dataObj.scale;
                return `transform:scale(${scale})`;
            };
        })
        const prev=()=> {
            if (dataObj.pdfEngineParams.pageNum > 1) {
                dataObj.pdfEngineParams.pageNum -= 1;
            }
        }
        const next=()=> {
            if (dataObj.pdfEngineParams.pageNum < dataObj.pdfEngineParams.numPages) {
                dataObj.pdfEngineParams.pageNum += 1;
            }
        }
        const zoomA=()=> {
            dataObj.scale += 0.2;
        }
        const zoomB=()=> {
            dataObj.scale -= 0.2;
            if(dataObj.scale<0)dataObj.scale=0;
        }
        return{
            ...toRefs(dataObj),scaleFun,prev,next,zoomA,zoomB
        }
    }
});